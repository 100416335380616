import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  grid: ({ smallGrid }) => ({
    marginTop: '10vw',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(26.5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    ...(smallGrid
      ? {
          [theme.breakpoints.up('sm')]: {
            '&:nth-child(-n+2)': {
              marginTop: '8vw',
            },
          },
          [theme.breakpoints.up('lg')]: {
            '&:nth-child(-n+3)': {
              marginTop: '8vw',
            },
          },
        }
      : {
          [theme.breakpoints.up('sm')]: {
            '&:nth-child(-n+2)': {
              marginTop: '8vw',
            },
          },
        }),
  }),
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.dark,
    '&:hover .featured-content-text': {
      textDecoration: 'underline',
    },
  },
  title: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.8125rem',
    },
  },
  text: ({ smallGrid }) => ({
    fontSize: smallGrid ? '2vw' : '2.3vw',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1vw',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: '2.92969vw',
      fontSize: '5vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: smallGrid ? '2vw' : '2vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: smallGrid ? '1.091405vw' : '1.455205vw',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: smallGrid ? '1.5rem' : '2rem',
    },
  }),
}));

const FeaturedContent = ({ entry, smallGrid, ...props }) => {
  const classes = useStyles({ smallGrid });

  const link = useMemo(() => {
    if (entry?.uri) return `/${entry.uri}`;

    return null;
  }, [entry]);

  const isLinkInternal = useMemo(
    () => /^\/(?!\/)/.test(link) && !['newsletters'].includes(entry?.sectionHandle),
    [link, entry]
  );

  const pictureProps = useMemo(() => {
    if (
      ['blog', 'cases', 'ideas', 'newsletters', 'newsletterArticles'].includes(entry?.sectionHandle)
    ) {
      return {
        alt: entry.featuredContent[0]?.image[0]?.title,
        ...entry.featuredContent[0]?.image[0],
      };
    }

    return {};
  }, [entry]);

  const content = useMemo(() => {
    switch (entry?.sectionHandle) {
      case 'blog':
        return (
          <>
            {/*
            <div className={classes.title}>
              {!!entry.categories[0] && `${entry.categories[0].title} — `}
              {format(new Date(entry.postDate), 'dd.MM.yyyy')}
            </div>
        */}
            {!!entry.categories[0] && (
              <div className={classes.title}>{entry.categories[0].title}</div>
            )}
            <h3 className={`featured-content-text ${classes.text}`}>{entry.title}</h3>
          </>
        );
      case 'cases':
      case 'ideas':
        return (
          <>
            <div className={classes.title}>{entry.title}</div>
            <h3 className={`featured-content-text ${classes.text}`}>
              {entry.featuredContent[0]?.text}
            </h3>
          </>
        );
      case 'newsletters':
        return (
          <>
            <div className={classes.title}>{format(new Date(entry.postDate), 'dd.MM.yyyy')}</div>
            <h3 className={`featured-content-text ${classes.text}`}>
              {entry.featuredContent[0]?.text}
            </h3>
          </>
        );
      case 'newsletterArticles':
        return (
          <>
            <div className={classes.title}>{entry.relatedObjectHeader}</div>
            <h3 className={`featured-content-text ${classes.text}`}>
              {entry.relatedObjectDescription}
            </h3>
          </>
        );
      default:
        return null;
    }
  }, [entry, classes]);

  return (
    <Grid item xs={12} sm={6} lg={smallGrid ? 4 : 6} className={classes.grid} {...props}>
      {isLinkInternal ? (
        <Link to={link} className={classes.link}>
          <Picture display='block' {...pictureProps} />
          <div className='space-m-t-6 sm-space-m-t-12 space-p-l-6 space-p-r-6'>{content}</div>
        </Link>
      ) : (
        <a href={link} className={classes.link}>
          <Picture display='block' {...pictureProps} />
          <div className='space-m-t-6 sm-space-m-t-12 space-p-l-6 space-p-r-6'>{content}</div>
        </a>
      )}
    </Grid>
  );
};

export default FeaturedContent;

FeaturedContent.propTypes = {
  entry: PropTypes.object.isRequired,
  smallGrid: PropTypes.bool,
};

FeaturedContent.defaultProps = {
  smallGrid: false,
};
