import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const mobile = css`
  font-size: 1.0625rem;
  line-height: 1.65;
`;

export default styled(Link)`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.dark};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
