import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FeaturedContent from './FeaturedContent';
import ReadMoreCta from './ReadMoreCta';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
}));

const FeaturedContentGrid = ({
  intro,
  entries,
  ctaUrl,
  readMoreCta,
  smallGrid,
  introGridProps,
  introProps,
}) => {
  const classes = useStyles();

  return (
    <>
      {!!intro && (
        <Grid container>
          <Grid item xs={12} sm={10} lg={8} xl={7} {...introGridProps}>
            <Box
              className='space-p-l-6 sm-space-m-b-8'
              dangerouslySetInnerHTML={{ __html: intro }}
              {...introProps}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={5} className={classes.gridContainer}>
        {entries.map(entry => (
          <FeaturedContent entry={entry} smallGrid={smallGrid} key={entry.id} />
        ))}
      </Grid>

      {readMoreCta && !!ctaUrl && (
        <Box mt={{ xs: 12, md: 19.5 }} textAlign='center'>
          <ReadMoreCta to={ctaUrl}>{readMoreCta}</ReadMoreCta>
        </Box>
      )}
    </>
  );
};

export default FeaturedContentGrid;

FeaturedContentGrid.propTypes = {
  intro: PropTypes.string,
  entries: PropTypes.array.isRequired,
  readMoreCta: PropTypes.string,
  ctaUrl: PropTypes.string,
  smallGrid: PropTypes.bool,
  introAlign: PropTypes.oneOf(['left', 'center', 'right']),
  introGridProps: PropTypes.object,
  introProps: PropTypes.object,
};

FeaturedContentGrid.defaultProps = {
  intro: null,
  readMoreCta: null,
  smallGrid: false,
  ctaUrl: null,
  introGridProps: {},
  introProps: {},
};
